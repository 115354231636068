/* WebKit (Safari/Chrome) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #333; /* 设置滚动条轨道的颜色，适应浅色模式 */
}

::-webkit-scrollbar-thumb {
  background: #666; /* 设置滚动条滑块的颜色 */
}

/* Firefox */
/* 滚动条轨道 */
/*scrollbar-width: thin; !* "auto" 会显示默认样式，"thin" 会显示自定义的样式 *!*/
/*scrollbar-color: #f1f1f1 #888; !* 滚动条颜色 *!*/

/* 暗色模式样式 */
@media (prefers-color-scheme: dark) {
  /* WebKit (Safari/Chrome) */
  ::-webkit-scrollbar-track {
    background: #333; /* 设置滚动条轨道的颜色，适应暗色模式 */
  }

  ::-webkit-scrollbar-thumb {
    background: #666; /* 设置滚动条滑块的颜色 */
  }

  /* Firefox */
  scrollbar-color: #333 #666; /* 滚动条颜色 */
}