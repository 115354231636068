
.bg_animation {
    position: relative;
    isolate: isolate;
    padding: 14px 8px;
    background-image: url('../../public/images/hero_background/home_hero_1_compress_3.jpg');
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    /*background-color: darkcyan;*/
    /*background-color: steelblue;*/
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
}

@keyframes bgPulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25); /* Adjust scale factor for desired enlargement */
    }
    100% {
        transform: scale(1);
    }
}

.bg_animation::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    background-blend-mode: inherit;
    background-color: inherit;
    z-index: -1; /* Ensure the pseudo-element is behind the content */
    animation: bgPulse 30s linear infinite alternate;
}

body {
    font-family: -apple-system, Roboto, SegoeUI, "Segoe UI", "Helvetica Neue", Helvetica, "Microsoft YaHei", "Meiryo UI", Meiryo, "Arial Unicode MS", sans-serif;
    background-color: rgba(255, 254, 252, 1);
    transition: background-color 1s;
}

.d-none {
    display: none;
}

.page {
}

.hero_container {
    min-height: 75rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%), url("../assets/logo/logo.png");
    background-size: cover;
    background-position: left top;
    background-color: transparent;
    background-blend-mode: multiply;
}

.hero {
    width: 80%;
}

.hero_logo_container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 1rem 1rem 1rem 3rem;
}

.hero_logo {
    width: 20rem;
}

.hero_heading_0 {
    font-size: 6.0rem;
    font-weight: 900;
    color: #00467f;
    max-width: 45rem;
    font-family: mundo-sans-std, sans-serif, serif;
}

.hero_heading_1 {
    font-size: 1.8rem;
    font-weight: 400;
    /*letter-spacing: 0.055em;*/
    line-height: 2.7rem;
    margin: 5.5rem 0 0 0rem;
    color: darkgray;
    max-width: 45rem;
    font-family: mundo-sans-std, sans-serif, serif;
}

.hero_heading_2 {
    margin: 4rem 0 0 0;
    font-size: 2rem;
    color: var(--text);
    line-height: 1.8em;
}

.hero_heading_2 a {
    color: #084691;
    text-decoration: none;
}

.hero_heading_2 a:hover {
    color: #073a78;
    text-decoration: none;
}

@media screen and (max-width: 768px) {

    .hero {
        width: 90%;
    }

    .hero_logo_container {

    }

    .hero_heading_0 {

    }

    .hero_heading_1 {

    }
}

@media screen and (max-width: 576px) {

    .hero {
    }

    .hero_logo_container {

    }

    .hero_heading_0 {
        font-size: 4rem;
        margin: 0 auto;
        text-align: center;
    }

    .hero_heading_1 {
        font-size: 1.8rem;
        margin: 6.5rem 0 0 0rem;
        text-align: center;
    }
}

/* section introContainer*/

.introContainerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15rem 0;
}

.introContainer {
    display: grid;
    grid-gap: 7rem;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
}

.introColumn {
    max-width: 50rem;
    min-width: 20rem;
    white-space: pre-line;
    margin: 1rem;
}

.introLeftTitle {
    color: #3a8dc7;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 4.5rem;
    margin-top: 0;
}

.introLeftText {
    font-size: 2.5rem;
    font-weight: 600;
}

.introRight {
    margin-top: 3.4rem;
}

.introRightText {
    font-size: 2.1rem;
}

@media screen and (max-width: 768px) {
    .introContainer {
        grid-auto-flow: row;
        grid-template-areas: inherit;
        grid-template-columns: 1fr;
    }
}


/*Section CountryTable*/

.country_table_section_wrapper {
    min-height: 70rem;
    /*width: 80%;*/
    max-width: 110rem;
    margin: 10rem auto;
}

@media screen and (max-width: 768px) {
    .country_table_section_wrapper {
        width: 90%;
    }
}

/*Section Report*/

.report_section_wrapper {
    width: 80%;
    max-width: 100rem;
    margin: 10rem auto;
}

@media screen and (max-width: 768px) {
    .report_section_wrapper {
        width: 90%;
    }
}


